<template>
  <div>
    <div>
      <p>Reaktivieren Sie bestehende Kontakte in wenigen Schritten. Wir helfen dabei:</p>
      <div
        v-if="!hasImportedLeads"
        class="d-flex flex-column"
      >
        <span class="text-subtitle-2 font-weight-regular">Unser Tipp: Importieren sie zuerst bereits bestehende Kontakte:</span>
        <v-btn
          outlined
          color="primary"
          :class="{'w-full':$vuetify.breakpoint.smAndDown, 'small-button':$vuetify.breakpoint.mdAndUp}"
          @click="triggerAppcuesFlow({slug:'import-contact'})"
        >
          {{ $t('buttons.acquisition-booster.import-contacts') }}
        </v-btn>
      </div>
      <span
        v-else
        class="text-subtitle-2 font-weight-regular"
      >
        Sie haben bereits einmal Kontakte importiert - sehr gut!
        Nun können Sie den Akquise-Booster starten oder <span @click="triggerAppcuesFlow({slug:'import-contact'})">weitere Kontakte importieren.</span>
      </span>

      <div class="d-flex flex-column mt-4">
        <span
          v-if="!hasImportedLeads"
          class="text-subtitle-2 font-weight-regular"
        >Anschließend reaktivieren Sie ihre Kontakte mit automatischen E-Mails in wenigen Schritten:</span>
        <span
          v-else
          class="text-subtitle-2 font-weight-regular"
        >Reaktivieren Sie ihre Kontakte mit automatischen E-Mails in wenigen Schritten:</span>
        <v-btn
          :outlined="!hasImportedLeads"
          color="primary"
          :class="{'w-full':$vuetify.breakpoint.smAndDown, 'small-button':$vuetify.breakpoint.mdAndUp}"
          @click="triggerAppcuesFlow({slug:'invite-contact'})"
        >
          Akquise-Booster starten
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import HAS_IMPORTED_LEADS from './HasImportedLeads.gql'
import { triggerAppcuesFlow } from '@/lib/appcues'

export default {
  methods: {
    triggerAppcuesFlow
  },
  apollo: {
    hasImportedLeads: {
      query: HAS_IMPORTED_LEADS
    }
  }

}
</script>
<style scoped>
.small-button{
  width: 300px;
}
</style>
